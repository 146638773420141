import React, { useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { AiFillStar, AiOutlineStar, AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import Navbar from './Navbar'; // Ensure this path is correct
import algaImage from '../assets/alga.jpeg.jpg';
import DreamImage from '../assets/Dream.jpg';
import jaicoImage from '../assets/Jaico.jpeg.jpg';
import kandirikalImage from '../assets/Kandirikal.jpeg.jpg';
import mattechImage from '../assets/Mattech.jpeg.jpg';
import swissImage from '../assets/Swiss360.jpeg.jpg';

const TopLists = () => {
  const cardsData = [
    {
      title: 'Elegant Jewelry',
      description: 'Explore our exclusive collection of elegant jewelry designed to make you stand out.',
      image: algaImage,
      rating: 4,
      location: 'New York, USA',
    },
    {
      title: 'Luxurious Watches',
      description: 'Discover the finest watches that combine luxury and precision.',
      image: DreamImage,
      rating: 5,
      location: 'Los Angeles, USA',
    },
    {
      title: 'Modern Art Pieces',
      description: 'Add a touch of modern art to your space with our curated selection.',
      image: jaicoImage,
      rating: 3,
      location: 'Chicago, USA',
    },
    {
      title: 'Luxury Cars',
      description: 'Experience the thrill of driving the latest luxury cars.',
      image: kandirikalImage,
      rating: 4,
      location: 'Houston, USA',
    },
    {
      title: 'Premium Beverages',
      description: 'Savor the taste of our premium beverages crafted to perfection.',
      image: mattechImage,
      location: 'Miami, USA',
    },
    {
      title: 'Designer Clothing',
      description: 'Upgrade your wardrobe with our collection of designer clothing.',
      image: swissImage,
      rating: 5,
      location: 'San Francisco, USA',
    },
  ];

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-6 sm:py-8 mt-4 sm:mt-12">
        <div className="text-center mb-6 sm:mb-8">
          <motion.h1
            className="text-2xl sm:text-4xl font-sans font-bold text-gray-900"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.2 }}
          >
            Shops Near You
          </motion.h1>
        </div>

        {/* Horizontal Scrollable Layout for Mobile */}
        <div className="flex space-x-4 overflow-x-scroll pb-2 sm:pb-4">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              description={card.description}
              image={card.image}
              rating={card.rating}
              location={card.location}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const Card = ({ title, description, image, rating, location }) => {
  const [isInWishlist, setIsInWishlist] = useState(false);
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      className="shadow-lg rounded-lg overflow-hidden relative min-w-[150px] sm:min-w-[220px] md:min-w-[250px] h-[250px] sm:h-[350px] transform transition-transform hover:scale-105"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      <img src={image} alt={title} className="w-full h-24 sm:h-36 object-cover" />
      <button
        className="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md hover:bg-gray-100 transition"
        onClick={() => setIsInWishlist(!isInWishlist)}
      >
        {isInWishlist ? (
          <AiFillHeart className="text-red-500" />
        ) : (
          <AiOutlineHeart className="text-gray-500" />
        )}
      </button>
      <div className="p-2 sm:p-3 bg-white border-t border-gray-200">
        <h2 className="text-xs sm:text-sm font-semibold text-gray-800 mb-1">{title}</h2>
        <p className="text-xs sm:text-base text-gray-600 mb-1">{description}</p>
        <div className="flex items-center mb-1">
          {Array.from({ length: 5 }, (_, i) => (
            i < rating ? (
              <AiFillStar key={i} className="text-yellow-400 text-xs sm:text-base" />
            ) : (
              <AiOutlineStar key={i} className="text-yellow-400 text-xs sm:text-base" />
            )
          ))}
        </div>
        <p className="text-xs sm:text-sm text-gray-500">{location}</p>
      </div>
    </motion.div>
  );
};

export default TopLists;
