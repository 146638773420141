import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "./bizmapia-logo.png";
import { useAuth } from "../context/AuthContext";

const Navbar = () => {
  const { isAuthenticated } = useAuth();
  const [searchTerm, setSearchTerm] = useState(""); // User's search term
  const [location, setLocation] = useState("Loading location..."); // User's location (human-readable)
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Get the user's current location when the component loads
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            reverseGeocode(latitude, longitude); // Convert lat/lon into city/town
          },
          (err) => {
            setError("Unable to fetch location.");
            console.error(err); // Log error for debugging
            setLocation("Unknown location");
          }
        );
      } else {
        setError("Geolocation is not supported by your browser.");
        setLocation("Unknown location");
      }
    };

    getLocation();
  }, []);

  // Reverse geocode to convert lat/lon into a human-readable address (city/town)
  const reverseGeocode = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=10&addressdetails=1`
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      const city = data.address.city || data.address.town || data.address.village || "Unknown location";
      setLocation(city); // Set location
    } catch (error) {
      setError("Failed to get location name.");
      console.error(error);
      setLocation("Unknown location");
    }
  };

  // Trigger search manually or automatically based on search term and location
  const handleSearch = () => {
    if (searchTerm) {
      const query = `query=${encodeURIComponent(searchTerm)}&location=${encodeURIComponent(location)}`;
      navigate(`/search?${query}`); // Navigate to search results
    }
  };

  // Perform automatic search when both searchTerm and location are available
  useEffect(() => {
    if (searchTerm && location !== "Loading location..." && location !== "Unknown location") {
      handleSearch();
    }
  }, [searchTerm, location]); // Trigger automatic search

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 120, damping: 20 }}
      className="bg-gradient-to-r from-gray-700 to-slate-600 text-white shadow-lg fixed top-0 w-full z-50"
    >
      <div className="container mx-auto px-1 sm:px-3 py-1 sm:py-2 flex items-center justify-between">
        <Link to="/" className="flex items-center space-x-1 sm:space-x-2">
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="text-lg sm:text-xl font-bold"
          >
            <img src={logo} alt="Bizmapia Logo" className="h-4 sm:h-6" />
          </motion.div>
        </Link>

        <div className="flex items-center flex-grow mx-1 sm:mx-3 bg-white rounded-md overflow-hidden">
          <input
            type="text"
            placeholder={`Search business in ${location}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
            className="w-full px-1 py-1 text-sm sm:px-3 sm:py-2 sm:text-base text-gray-700 focus:outline-none"
          />
          {/* Manual Search Button */}
          <button
            onClick={handleSearch}
            className="bg-yellow-500 hover:bg-yellow-600 text-white px-2 py-1 sm:px-3 sm:py-2"
          >
            <svg
              className="h-5 w-5 sm:h-6 sm:w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-4.35-4.35m2.1-5.65a7.5 7.5 0 10-15 0 7.5 7.5 0 0015 0z"
              />
            </svg>
          </button>
        </div>

        <div className="flex items-center space-x-1 sm:space-x-2">
          <Link to="/register">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gray-500 text-white px-1 py-1 text-xs sm:px-3 sm:py-2 sm:text-sm rounded-md font-semibold transition duration-300 ease-in-out hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              {isAuthenticated ? "Dashboard" : "Sign Up / Sign In"}
            </motion.button>
          </Link>
        </div>
      </div>
      {error && <div className="text-red-500 text-center">{error}</div>} {/* Display error messages */}
    </motion.nav>
  );
};

export default Navbar;
