import React from 'react';
import logo from './bizmapia-logo.png'; // Ensure this path is correct
import bismapiaPDF from './Bismapia basic document.PDF'; // Import the PDF file
import CustomTabBar from './CustomTabBar'; // Import CustomTabBar

const LogoPage = () => {
  const handleLinkClick = () => {
    // Open the PDF file in a new tab
    window.open(bismapiaPDF, '_blank'); // This should point to the imported PDF
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black text-white"> {/* Added black background and white text */}
      <CustomTabBar /> {/* Include the CustomTabBar component */}
      <img src={logo} alt="Bizmapia Logo" className="mb-4 w-48 h-auto" />
      <button
        onClick={handleLinkClick}
        className="text-blue-400 underline" // Changed text color to differentiate
      >
        Click Here
      </button>
    </div>
  );
};

export default LogoPage;
