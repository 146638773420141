import React, { useState } from 'react';

const CustomForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    mobile: '',
    place: '',
    municipality: '',
    age: '',
    dob: '',
    date: '',
    status: '',
    declaration: false,
    declarationDate: '',
    declarationPlace: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  // Enhanced CSS Styles
  const styles = {
    formContainer: {
      maxWidth: '700px',
      margin: '0 auto',
      padding: '30px',
      backgroundColor: '#ffffff',
      borderRadius: '15px',
      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
    },
    formHeading: {
      textAlign: 'center',
      fontSize: '2rem',
      color: '#333',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    formDescription: {
      textAlign: 'center',
      fontSize: '1.2rem',
      color: '#777',
      marginBottom: '30px',
    },
    formElement: {
      marginBottom: '20px',
    },
    label: {
      fontWeight: 'bold',
      display: 'block',
      marginBottom: '10px',
      color: '#555',
    },
    input: {
      width: '100%',
      padding: '12px 15px',
      borderRadius: '8px',
      border: '1px solid #ccc',
      fontSize: '16px',
      backgroundColor: '#f9f9f9',
      transition: 'all 0.3s ease',
    },
    inputFocus: {
      borderColor: '#4CAF50',
    },
    textarea: {
      width: '100%',
      height: '120px',
      padding: '12px 15px',
      borderRadius: '8px',
      border: '1px solid #ccc',
      backgroundColor: '#f9f9f9',
      fontSize: '16px',
      resize: 'none',
    },
    submitButton: {
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '15px 25px',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: '18px',
      textAlign: 'center',
      display: 'block',
      margin: '0 auto',
      transition: 'all 0.3s ease',
    },
    submitButtonHover: {
      backgroundColor: '#45a049',
    },
    radioGroup: {
      display: 'flex',
      gap: '15px',
    },
    checkboxGroup: {
      marginTop: '10px',
    },
    checkboxLabel: {
      fontSize: '16px',
      color: '#555',
    },
  };

  return (
    <form onSubmit={handleSubmit} style={styles.formContainer}>
      {/* Form Heading */}
      <h1 style={styles.formHeading}>FRANCHISOR BIODATA</h1>
      
      {/* Form Description */}
      <p style={styles.formDescription}>Please fill in your details as requested</p>

      {/* Name */}
      <div style={styles.formElement}>
        <label htmlFor="name" style={styles.label}>Name *</label>
        <input 
          type="text" 
          id="name" 
          name="name" 
          value={formData.name} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Address */}
      <div style={styles.formElement}>
        <label htmlFor="address" style={styles.label}>Address *</label>
        <input 
          type="text" 
          id="address" 
          name="address" 
          value={formData.address} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Mobile */}
      <div style={styles.formElement}>
        <label htmlFor="mobile" style={styles.label}>Mobile Number *</label>
        <input 
          type="tel" 
          id="mobile" 
          name="mobile" 
          value={formData.mobile} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Place */}
      <div style={styles.formElement}>
        <label htmlFor="place" style={styles.label}>Place *</label>
        <input 
          type="text" 
          id="place" 
          name="place" 
          value={formData.place} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Municipality/Town */}
      <div style={styles.formElement}>
        <label htmlFor="municipality" style={styles.label}>Currently living nearby municipality/town *</label>
        <input 
          type="text" 
          id="municipality" 
          name="municipality" 
          value={formData.municipality} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Age */}
      <div style={styles.formElement}>
        <label htmlFor="age" style={styles.label}>Age *</label>
        <input 
          type="number" 
          id="age" 
          name="age" 
          value={formData.age} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Date of Birth */}
      <div style={styles.formElement}>
        <label htmlFor="dob" style={styles.label}>Date of Birth *</label>
        <input 
          type="date" 
          id="dob" 
          name="dob" 
          value={formData.dob} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Date */}
      <div style={styles.formElement}>
        <label htmlFor="date" style={styles.label}>Date *</label>
        <input 
          type="date" 
          id="date" 
          name="date" 
          value={formData.date} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Current Status */}
      <div style={styles.formElement}>
        <label style={styles.label}>Current Status *</label>
        <div style={styles.radioGroup}>
          <label>
            <input 
              type="radio" 
              name="status" 
              value="Employed" 
              checked={formData.status === 'Employed'} 
              onChange={handleChange} 
            /> Employed
          </label>
          <label>
            <input 
              type="radio" 
              name="status" 
              value="Unemployed" 
              checked={formData.status === 'Unemployed'} 
              onChange={handleChange} 
            /> Unemployed
          </label>
          <label>
            <input 
              type="radio" 
              name="status" 
              value="Business" 
              checked={formData.status === 'Business'} 
              onChange={handleChange} 
            /> Business
          </label>
          <label>
            <input 
              type="radio" 
              name="status" 
              value="Others" 
              checked={formData.status === 'Others'} 
              onChange={handleChange} 
            /> Others
          </label>
        </div>
      </div>

      {/* Declaration */}
      <div style={styles.formElement}>
        <p style={styles.label}>
          "I do hereby declare that I agree to co-operate with the company for the successful proceedings of company documentation, document verification, and tender formalities..."
        </p>
        <label>
          <input 
            type="checkbox" 
            name="declaration" 
            checked={formData.declaration} 
            onChange={handleChange} 
            required 
          /> I agree to the above declaration.
        </label>
      </div>

      {/* Declaration Date */}
      <div style={styles.formElement}>
        <label htmlFor="declarationDate" style={styles.label}>Date *</label>
        <input 
          type="date" 
          id="declarationDate" 
          name="declarationDate" 
          value={formData.declarationDate} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Declaration Place */}
      <div style={styles.formElement}>
        <label htmlFor="declarationPlace" style={styles.label}>Place *</label>
        <input 
          type="text" 
          id="declarationPlace" 
          name="declarationPlace" 
          value={formData.declarationPlace} 
          onChange={handleChange} 
          required 
          style={styles.input} 
        />
      </div>

      {/* Submit Button */}
      <div style={styles.formElement}>
        <button type="submit" style={styles.submitButton}>Submit</button>
      </div>
    </form>
  );
};

export default CustomForm;
